import yoodylogo from "../assets/images/yoody_logo.png"
import cover1 from "../assets/images/cover1.jpg"
import cover2 from "../assets/images/cover2.jpg"
import cover3 from "../assets/images/cover3.jpg"
import facebook from "../assets/images/facebook.svg"
import twitter from "../assets/images/twitter.svg"
import linkedin from "../assets/images/linkedin.svg"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  yoody_logo: yoodylogo,
  cover_1: cover1,
  cover_2: cover2,
  cover_3: cover3,
  facebookIcon: facebook,
  twitterIcon: twitter,
  linkedinIcon: linkedin,
}
