import React, { useCallback, useEffect, useState } from "react"
//@ts-ignore
import { useMediaQuery } from "react-responsive"
import MenuHeader from "./MenuHeader"
import PageHeader from "./PageHeader"

let timer: any = null

let disableScrollListener = false

export default function Header() {
  const [selectedPos, setSelectedPos] = useState(1)

  const checkConsHitTop = useCallback(() => {
    if (disableScrollListener) {
      return
    }
    const pos = selectedPos

    const contactCon = document.getElementById("contactCon")!
    const contactConHitTop = contactCon.getBoundingClientRect().top < 300

    if (contactConHitTop) {
      pos !== 2 && setSelectedPos(2)
      return
    } else {
      pos !== 1 && setSelectedPos(1)
    }
  }, [selectedPos])

  const handleScroll = useCallback(() => {
    checkConsHitTop()

    // change background

    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(function () {
      disableScrollListener = false
    }, 150)
  }, [checkConsHitTop])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  const onHomeClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const onContactClick = () => {
    const element = document.getElementById("contactCon")!
    window.scrollTo({
      top: element.offsetTop - 200,
      behavior: "smooth",
    })
  }

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })
  if (isMobile) {
    return (
      <MenuHeader
        onHomeClick={onHomeClick}
        onContactClick={onContactClick}
        selectedPos={selectedPos}
        setSelectedPos={setSelectedPos}
      />
    )
  }

  return (
    <PageHeader
      onHomeClick={onHomeClick}
      onContactClick={onContactClick}
      selectedPos={selectedPos}
    />
  )
}
