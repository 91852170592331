import React from "react"
import styled from "styled-components"
//@ts-ignore
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import images from "./common/images"
import Helper from "./utils/Helper"

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin-top: ${Helper.pxToVw(120)};
  height: 50vh;

  @media (max-width: 800px) {
    margin-top: ${Helper.pxToVw(48, 375)};
    height: 40vh;
  }

  @media (min-width: 1400px) {
    height: 70vh;
  }
`

const Img = styled.img`
  width: 100vw;
  height: 100%;
  object-fit: cover;
`

const Title = styled.p`
  font-size: ${Helper.pxToVw(70)};
  font-weight: bold;
  color: white;
  margin: 0;
  margin-top: ${Helper.pxToVw(100)};

  @media (max-width: 800px) {
    margin-top: ${Helper.pxToVw(50, 375)};
    font-size: ${Helper.pxToVw(30, 375)};
  }
`

const Text = styled.p`
  font-size: ${Helper.pxToVw(50)};
  color: #e6e6e6;
  margin: 0;
  margin-top: ${Helper.pxToVw(20)};

  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(14, 375)};
    margin-top: ${Helper.pxToVw(5, 375)};
  }
`

const Overlay = styled.div`
  position: absolute;
  height: 50vh;
  right: 0;
  left: 0;
  padding-left: ${Helper.pxToVw(50)};
  @media (max-width: 800px) {
    top: ${Helper.pxToVw(48, 375)};
    height: 40vh;
  }

  @media (min-width: 1400px) {
    height: 70vh;
  }
  background-color: rgba(0, 0, 0, 0.5);
`

SwiperCore.use([Autoplay])

export default function HomeCon() {
  return (
    <Container id="homeCon">
      <Overlay style={{ zIndex: 10 }}>
        <Title>Yoody Tech,</Title>
        <Text>Custom Software Solutions</Text>
      </Overlay>
      <Swiper
        loop
        autoplay={{ delay: 2500 }}
        slidesPerView={1}
        slidesPerColumn={1}
        style={{
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      >
        <SwiperSlide>
          <Img alt="cover1" src={images.cover_1} />
        </SwiperSlide>
        <SwiperSlide>
          <Img alt="cover2" src={images.cover_2} />
        </SwiperSlide>
        <SwiperSlide>
          <Img alt="cover3" src={images.cover_3} />
        </SwiperSlide>
      </Swiper>
    </Container>
  )
}
