import React from "react"
import styled from "styled-components"
import images from "./common/images"
import Helper from "./utils/Helper"
import "./popup.css"
import Popup from "reactjs-popup"

const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  margin-top: ${Helper.pxToVw(80)};
`

const Header = styled.p`
  font-size: ${Helper.pxToVw(50)};
  color: #005ce6;
  margin: 0;
  font-weight: bold;

  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(24, 375)};
  }
`

const Button = styled.button`
  padding: 0;
  margin-top: ${Helper.pxToVw(80)};
  width: ${Helper.pxToVw(280)};
  height: ${Helper.pxToVw(80)};
  border-radius: ${Helper.pxToVw(60)};
  background-color: #005ce6;
  border: none;
  outline: none;
  color: white;
  font-size: ${Helper.pxToVw(30)};
  line-height: normal;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: ${Helper.pxToVw(1.2)};

  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(12, 375)};
    margin-top: ${Helper.pxToVw(30, 375)};
    border-radius: ${Helper.pxToVw(30, 375)};
    width: ${Helper.pxToVw(100, 375)};
    height: ${Helper.pxToVw(34, 375)};
  }

  &:hover {
    background-color: #003d99;
  }
`

const SocialCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${Helper.pxToVw(400)};
  margin-bottom: ${Helper.pxToVw(200)};

  @media (max-width: 800px) {
    margin-top: ${Helper.pxToVw(120, 375)};
    margin-bottom: ${Helper.pxToVw(120, 375)};
  }
`

const ImgDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Img = styled.img`
  width: ${Helper.pxToVw(36)};
  height: ${Helper.pxToVw(36)};
  @media (max-width: 800px) {
    width: ${Helper.pxToVw(14, 375)};
    height: ${Helper.pxToVw(14, 375)};
  }
  object-fit: contain;
  cursor: pointer;
`

const CenterImg = styled(Img)`
  margin-right: ${Helper.pxToVw(20)};
  margin-left: ${Helper.pxToVw(20)};

  @media (max-width: 800px) {
    margin-right: ${Helper.pxToVw(10, 375)};
    margin-left: ${Helper.pxToVw(10, 375)};
  }
`

const Text = styled.p`
  white-space: pre-line;

  margin: 0;
  padding: 0;
  font-size: ${Helper.pxToVw(20)};
  margin-top: ${Helper.pxToVw(30)};
  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(8, 375)};
    margin-top: ${Helper.pxToVw(10, 375)};
  }
`

const Description = styled.p`
  white-space: pre-line;
  margin: 0;
  padding: 0;
  font-size: ${Helper.pxToVw(30)};
  margin-top: ${Helper.pxToVw(50)};
  margin-left: ${Helper.pxToVw(20)};
  margin-right: ${Helper.pxToVw(20)};
  text-align: center;
  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(12, 375)};
    margin-top: ${Helper.pxToVw(20, 375)};
    margin-left: ${Helper.pxToVw(10, 375)};
    margin-right: ${Helper.pxToVw(10, 375)};
  }
`

const Privacy = styled.a`
  margin: 0;
  padding: 0;
  font-size: ${Helper.pxToVw(20)};
  margin-top: ${Helper.pxToVw(30)};
  cursor: pointer;
  font-weight: 600;

  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(8, 375)};
    margin-top: ${Helper.pxToVw(10, 375)};
  }
`

const PopUpBodyCon = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${Helper.pxToVw(20)};
  box-shadow: 0 0 ${Helper.pxToVw(20)} 0 rgba(0, 0, 0, 0.3);

  @media (max-width: 800px) {
    border-radius: ${Helper.pxToVw(20, 375)};
    box-shadow: 0 0 ${Helper.pxToVw(20, 375)} 0 rgba(0, 0, 0, 0.3);
  }
`

const PopUpContentCon = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  width: 70vw;
  max-height: ${Helper.pxToVw(540)};
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${Helper.pxToVw(24)} ${Helper.pxToVw(24)} ${Helper.pxToVw(24)}
    ${Helper.pxToVw(24)};
  @media (max-width: 800px) {
    width: 90vw;
    padding: ${Helper.pxToVw(8, 375)} ${Helper.pxToVw(8, 375)}
      ${Helper.pxToVw(8, 375)} ${Helper.pxToVw(8, 375)};
    max-height: ${Helper.pxToVw(375, 375)};
  }
`

const PopupTitle = styled.p`
  margin: 0;
  font-size: ${Helper.pxToVw(40)};
  line-height: 1.54;
  font-weight: bold;
  font-stretch: normal;
  font-family: Bold;
  font-style: normal;
  letter-spacing: normal;
  margin-top: ${Helper.pxToVw(16)};

  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(20, 375)};
    margin-top: ${Helper.pxToVw(16, 375)};
  }
`

const PopupCloseButton = styled.button`
  cursor: pointer;
  white-space: pre-line;

  width: ${Helper.pxToVw(180)};
  height: ${Helper.pxToVw(40)};
  align-self: center;
  margin-top: ${Helper.pxToVw(20)};
  margin-bottom: ${Helper.pxToVw(20)};
  border-radius: ${Helper.pxToVw(10)};

  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  font-weight: 600;
  background-color: #005ce6;

  @media (max-width: 800px) {
    width: ${Helper.pxToVw(80, 375)};
    height: ${Helper.pxToVw(20, 375)};
    margin-top: ${Helper.pxToVw(8, 375)};
    margin-bottom: ${Helper.pxToVw(8, 375)};
    border-radius: ${Helper.pxToVw(10, 375)};
  }
`

const PopupPlain = styled.span`
  margin: 0;
  font-size: ${Helper.pxToVw(16)};
  font-weight: normal;
  font-stretch: normal;
  font-family: Regular;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  margin-top: ${Helper.pxToVw(8)};

  @media (max-width: 800px) {
    font-size: ${Helper.pxToVw(14, 375)};
    margin-top: ${Helper.pxToVw(8, 375)};
  }
`

const description =
  "Do you have an idea for a mobile or desktop app but lack the expertise to build it yourself?\nYoody Tech brings decades of experience building high-throughput, low-latency systems that require 24/8 uptime.\nEmail us today to find out how we can partner together to make your idea a reality."

export default function ContactCon() {
  return (
    <Container id="contactCon">
      <Header>Contact Us</Header>
      <Description>{description}</Description>
      <Button
        onClick={() => {
          window.location.href =
            "mailto:info@yoodytech.com?subject=request%20for%20information"
        }}
      >
        Tell Me More
      </Button>
      <SocialCon>
        <ImgDiv>
          <Img alt="facebook" src={images.facebookIcon} />
          <CenterImg alt="twitter" src={images.twitterIcon} />
          <Img alt="linkedin" src={images.linkedinIcon} />
        </ImgDiv>
        <Text>
          @2021 by Yoody Tech LLC |
          <Popup
            className="popup"
            trigger={<Privacy> Privacy</Privacy>}
            modal
            nested
          >
            {(close: Function) => {
              return (
                <PopUpBodyCon>
                  <PopUpContentCon>
                    <PopupTitle>Privacy</PopupTitle>
                    <PopupPlain>{content}</PopupPlain>
                  </PopUpContentCon>
                  <PopupCloseButton
                    onClick={() => {
                      close()
                    }}
                  >
                    Close
                  </PopupCloseButton>
                </PopUpBodyCon>
              )
            }}
          </Popup>
        </Text>
      </SocialCon>
    </Container>
  )
}

const content =
  "Yoody Tech only retains such contact info as is necessary to communicate with our clients. We never share this data with any third party."
