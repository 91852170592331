/* eslint-disable no-script-url */
import React, { useState } from "react"
import styled from "styled-components"
import images from "../common/images"
import Helper from "../utils/Helper"
import MenuIcon from "./component/MenuIcon"

const Container = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  height: ${Helper.pxToVw(48, 375)};
  position: fixed;
  top: 0;
  transition: background 1s;
  background-color: white;
`

const ResponsiveImg = styled.img`
  width: ${Helper.pxToVw(40, 375)};
  height: ${Helper.pxToVw(40, 375)};
  margin-left: ${Helper.pxToVw(24, 375)};
  object-fit: contain;
`

const SlideMenuCon = styled.div<{ toggle: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  background-color: white;
  border-bottom-right-radius: ${Helper.pxToVw(8, 375)};
  border-bottom-left-radius: ${Helper.pxToVw(8, 375)};
  ${(props) => {
    if (props.toggle) {
      return `
      position: absolute;
    left: 0;
    top: ${Helper.pxToVw(48, 375)};
    `
    } else {
      return `
    display: none
    `
    }
  }}
`

const SectionComp = styled.a<{ toggle: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${Helper.pxToVw(50)};
  margin-left: ${Helper.pxToVw(16, 375)};
  margin-right: ${Helper.pxToVw(16, 375)};
  font-family: Bold;
  font-size: ${Helper.pxToVw(15, 375)};
  height: ${Helper.pxToVw(42, 375)};
  border-radius: ${Helper.pxToVw(4, 375)};
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: rgb(0, 110, 255, 0.05);
    color: #005ce6;
    border-left: ${Helper.pxToVw(5, 375)} solid #005ce6;
  }

  ${(props) => {
    if (props.toggle) {
      return `
      background-color: rgb(0, 110, 255, 0.05);
      color:  #005ce6;
      border-left: ${Helper.pxToVw(5, 375)} solid #005ce6;
      `
    }
  }}
`

type MenuHeaderProps = {
  onHomeClick: () => void
  onContactClick: () => void
  selectedPos: number
  setSelectedPos: React.Dispatch<React.SetStateAction<number>>
}

let tempSelectedPos = 0

export default function MenuHeader({
  onHomeClick,
  onContactClick,
  selectedPos,
  setSelectedPos,
}: MenuHeaderProps) {
  const [toggle, setToggle] = useState(false)

  const onClick = (position: number) => {
    setToggle(false)
    // setSelectedPos(position)
    tempSelectedPos = position

    switch (position) {
      case 1:
        onHomeClick()
        break
      case 2:
        onContactClick()
        break
    }
  }

  const onMouseOver = (position: number) => {
    tempSelectedPos = selectedPos
    setSelectedPos(position)
  }

  const onMouseLeave = () => {
    setSelectedPos(tempSelectedPos)
  }

  return (
    <Container style={{ zIndex: 100 }}>
      <ResponsiveImg alt="logo" src={images.yoody_logo} />
      <div style={{ flexGrow: 1 }} />
      <MenuIcon toggle={toggle} toggleClick={() => setToggle(!toggle)} />
      <SlideMenuCon style={{ zIndex: 100 }} toggle={toggle}>
        <SectionComp
          onMouseLeave={onMouseLeave}
          onMouseOver={() => onMouseOver(1)}
          toggle={selectedPos === 1}
          onClick={() => onClick(1)}
        >
          Home
        </SectionComp>
        <SectionComp
          onMouseLeave={onMouseLeave}
          onMouseOver={() => onMouseOver(2)}
          toggle={selectedPos === 2}
          onClick={() => onClick(2)}
        >
          Contact Us
        </SectionComp>
      </SlideMenuCon>
    </Container>
  )
}
