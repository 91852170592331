import React from "react"
import styled from "styled-components"
import "./App.css"
import ContactCon from "./ContactCon"
import Header from "./header"
import HomeCon from "./HomeCon"

const LandingPageContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  scroll-behavior: smooth;
`

function App() {
  return (
    <LandingPageContainer>
      <HomeCon />
      <ContactCon />
      <Header />
    </LandingPageContainer>
  )
}

export default App
