import React from "react"
import styled from "styled-components"
import images from "../common/images"
import Helper from "../utils/Helper"
import HeaderSection from "./component/HeaderSection"

const ResponsiveImg = styled.img`
  width: ${Helper.pxToVw(100)};
  height: ${Helper.pxToVw(100)};
  object-fit: contain;
  margin-left: ${Helper.pxToVw(231)};
`

const Container = styled.div<{ position: number }>`
  display: flex;
  width: 100vw;
  align-items: center;
  height: ${Helper.pxToVw(120)};
  position: fixed;
  top: 0;
  transition: background 1s;
  background-color: white;
  ${(props) => {
    if (props.position === 2) {
      return `
      border-bottom: ${Helper.pxToVw(1)} solid #999999;
     `
    }
  }}
`

const SectionComp = styled(HeaderSection)`
  margin-right: ${Helper.pxToVw(100)};
`

const SectionCon = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 10vw;
`

type PageHeaderProps = {
  onHomeClick: () => void
  onContactClick: () => void
  selectedPos: number
}

export default function PageHeader({
  onHomeClick,
  onContactClick,
  selectedPos,
}: PageHeaderProps) {
  return (
    <Container position={selectedPos} style={{ zIndex: 1000 }}>
      <ResponsiveImg alt="logo" src={images.yoody_logo} />
      <SectionCon>
        <SectionComp
          onClick={onHomeClick}
          text="Home"
          isSelected={selectedPos === 1}
        />
        <SectionComp
          onClick={onContactClick}
          text="Contact Us"
          isSelected={selectedPos === 2}
        />
      </SectionCon>
    </Container>
  )
}
