import React from "react"
import styled from "styled-components"
import Helper from "../../utils/Helper"

const Line = styled.span`
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: black;
  border-radius: ${Helper.pxToVw(8)};
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
`

const Container = styled.div`
  width: ${Helper.pxToVw(24, 375)};
  height: ${Helper.pxToVw(24, 375)};
  position: relative;
  margin-right: ${Helper.pxToVw(16, 375)};
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
`

const FirstLine = styled(Line)<{ toggle: boolean }>`
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  ${(props) => {
    if (props.toggle) {
      return `
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: ${Helper.pxToVw(0, 357)};
      left: ${Helper.pxToVw(5, 347)};
      `
    } else {
      return `
      top: 0;
     
      `
    }
  }}
`

const SecondLine = styled(Line)<{ toggle: boolean }>`
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;

  ${(props) => {
    if (props.toggle) {
      return `
      width: 0%;
      opacity: 0;
      `
    } else {
      return `
      top: ${Helper.pxToVw(8, 375)};
      `
    }
  }}
`

const ThirdLine = styled(Line)<{ toggle: boolean }>`
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  ${(props) => {
    if (props.toggle) {
      return `
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: ${Helper.pxToVw(16.5, 375)};
      left: ${Helper.pxToVw(5, 357)};
      `
    } else {
      return `
      top: ${Helper.pxToVw(16, 375)};
      
      `
    }
  }}
`

type MenuIconProps = {
  toggleClick: () => void
  toggle: boolean
}

export default function MenuIcon({ toggle, toggleClick }: MenuIconProps) {
  return (
    <Container onClick={toggleClick}>
      <FirstLine toggle={toggle} />
      <SecondLine toggle={toggle} />
      <ThirdLine toggle={toggle} />
    </Container>
  )
}
