import React from "react"
import styled from "styled-components"
import Helper from "../../utils/Helper"

const Container = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${Helper.pxToVw(80)};
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Text = styled.p<{ isSelected: boolean }>`
  font-weight: 600;
  font-size: ${Helper.pxToVw(20)};
  color: ${(props) => (props.isSelected ? "#005ce6" : "black")};
`

const Underline = styled.div<{ isSelected: boolean }>`
  width: ${Helper.pxToVw(36)};
  height: ${Helper.pxToVw(4)};
  border-radius: ${Helper.pxToVw(2)};
  background-color: #005ce6;
  opacity: ${(props) => (props.isSelected ? 1 : 0)};
`

type HeaderSectionProps = {
  className?: string
  isSelected: boolean
  text: string
  style?: any
  onClick: () => void
}

export default function HeaderSection({
  className,
  isSelected,
  text,
  style = {},
  onClick,
}: HeaderSectionProps) {
  return (
    <Container onClick={onClick} style={style} className={className}>
      <Underline isSelected={isSelected} />
      <TextContainer>
        <Text isSelected={isSelected}>{text}</Text>
      </TextContainer>
    </Container>
  )
}
